<script setup lang="ts">
import { ref, watch, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { useMutation } from '@tanstack/vue-query';
import { type Patient } from '@/api/patients';
import { shiftsApi } from '@/api/shifts';
import type { AxiosError } from 'axios';
import { useFilters } from '@/composables/useFilters';

const { t } = useI18n();

const emit = defineEmits(['close', 'refetch', 'success']);

const props = defineProps({
  patient: {
    type: Object as PropType<Patient>,
    required: true,
  },
});

const validationSchema = yup.object({
  lastDate: yup.string().required(t('shiftForm.fieldRequired')),
});

const initialValues = {
  patientId: props.patient.id,
  lastDate: undefined,
};

const { handleSubmit, values, errors, defineField } = useForm({ validationSchema, initialValues });

const [lastDate, lastDateAttrs] = defineField('lastDate');

const queryParams = useFilters(values);

const { mutate, isError, error, isPending, isSuccess } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => shiftsApi.destroy(undefined, queryParams.value),
    onSuccess: () => {
      emit('refetch');
      emit('success');
    },
  },
);

const submitButtonDisabled = ref(false);
const onSubmit = handleSubmit(() => {
  submitButtonDisabled.value = true;
  mutate();
});

watch(isSuccess, async () => {
  if (isSuccess.value === true) {
    const waitMs = 1500;
    await new Promise((resolve) => setTimeout(resolve, waitMs));
    emit('close');
  }
});
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title>
      {{ t('shiftBatchDestroyForm.title') }}
    </v-card-title>
    <v-form
      v-if="!isPending && !isSuccess"
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-card flat>
        <v-card-text class="mb-4">
          {{ t('shiftBatchDestroyForm.explanation') }}
        </v-card-text>
        <v-text-field
          v-model="lastDate"
          type="date"
          v-bind="lastDateAttrs"
          variant="outlined"
          :label="t('shiftBatchDestroyForm.lastDate')"
          :error-messages="errors.lastDate"
        />
        <v-card-text v-if="lastDate">
          {{ t('shiftBatchDestroyForm.confirmation') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text="Cerrar"
            @click="$emit('close')"
          />
          <v-spacer />
          <v-btn
            v-if="lastDate"
            color="red"
            type="submit"
            :disabled="submitButtonDisabled"
          >
            {{ t('shiftBatchDestroyForm.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-col
      v-if="isPending"
      class="pb-20"
    >
      <v-row class="justify-center d-flex">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="primary"
        />
      </v-row>
      <v-row class="justify-center mt-8 d-flex">
        <p>Estamos eliminando los turnos, esto puede tomar unos segundos...</p>
      </v-row>
    </v-col>
    <v-col v-if="isSuccess">
      <v-row class="justify-center d-flex">
        <v-icon
          icon="mdi-check-circle"
          color="primary"
          size="50"
        />
      </v-row>
      <v-row class="justify-center mt-8 d-flex">
        <p>Turnos eliminados exitosamente!</p>
      </v-row>
    </v-col>
    <v-alert
      v-if="isError && error"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ error?.response?.status === 404 ? t('shiftBatchDestroyForm.notFoundError') : t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
