import { api } from './index';

const BASE_PATH = '/api/internal/caregiver_documents';

export type CaregiverDocument = {
  id: number;
  caregiverId: number;
  name: string;
  documentUrl: string;
}

export interface CaregiverDocumentForm {
  id?: number;
  caregiverId: number;
  name: string;
  document: File;
}

export interface CaregiverDocumentUdpateForm {
  id?: number;
  name: string;
}

export const caregiverDocumentsApi = {
  index(caregiverId: number): Promise<CaregiverDocument[]> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?caregiver_id=${caregiverId}`,
    }).then(response => response.data.caregiverDocuments);
  },
  create(caregiverDocumentForm: FormData): Promise<CaregiverDocument> {
    return api({
      method: 'post',
      url: BASE_PATH,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: caregiverDocumentForm,
    }).then(response => response.data.caregiverDocument);
  },
  update(caregiverDocumentForm: CaregiverDocumentUdpateForm): Promise<CaregiverDocument> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${caregiverDocumentForm.id}`,
      data: { caregiverDocument: caregiverDocumentForm },
    }).then(response => response.data.caregiverDocument);
  },
  destroy(id: number): Promise<void> {
    return api({
      method: 'delete',
      url: `${BASE_PATH}/${id}`,
    }).then(() => {});
  },
};
