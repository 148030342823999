<script setup lang="ts">
import { ref } from 'vue';
import CalendarDay from './calendar-day.vue';

const date = ref(new Date());
const days = [
  {
    id: 1,
    date: new Date().setDate(date.value.getDate() + 1),
    events: [
      {
        id: 1,
        title: '8:00 - 20:00',
        description: 'María González',
      },
    ],
  },
  {
    id: 2,
    date: new Date().setDate(date.value.getDate() + 2), // eslint-disable-line no-magic-numbers
    events: [
      {
        id: 3,
        title: '8:00 - 20:00',
        description: 'María González',
      },
    ],
  },
  {
    id: 3,
    date: new Date().setDate(date.value.getDate() + 3), // eslint-disable-line no-magic-numbers
    events: [],
  },
];

function onDateChange() {
  console.log('Date changed');
}

</script>
<template>
  <v-container>
    <v-col>
      <v-row>
        <v-btn href="/patients/1">
          <v-icon left>
            mdi-chevron-left
          </v-icon>
          Eugenio Pérez
        </v-btn>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <v-row class="hidden-md-and-up">
            <v-col>
              <v-text-field
                v-model="date"
                type="date"
                label="Selecciona una Fecha"
              />
            </v-col>
          </v-row>
          <v-row
            class="d-flex"
            justify-md="space-around"
          >
            <v-col class="v-col-4 hidden-sm-and-down">
              <v-date-picker
                v-model="date"
                hide-header
                @input="onDateChange"
              />
            </v-col>
            <v-col class="v-col-12 v-col-md-6">
              <calendar-day
                v-for="day in days"
                :key="day.id"
                :day="new Date(day.date)"
                :events="day.events"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>
