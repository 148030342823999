import { api } from './index';
import { type Caregiver } from './caregivers';

const BASE_PATH = '/api/internal/available_caregivers';

export const availableCaregiversApi = {
  index(shiftId: number): Promise<Array<Caregiver>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?shift_id=${shiftId}`,
    }).then(response => response.data.caregivers);
  },
};
