<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation } from '@tanstack/vue-query';
import { shiftsApi } from '@/api/shifts';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const emit = defineEmits(['close', 'success']);

const props = defineProps({
  shiftId: {
    type: Number,
    required: true,
  },
  closeButtonText: {
    type: String,
    default: 'Cerrar',
  },
});

const { handleSubmit } = useForm();

const { mutate, isError, isPending, isSuccess } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => shiftsApi.destroy(props.shiftId, undefined),
    onSuccess: () => {
      emit('success');
      emit('close');
    },
  },
);

const onSubmit = handleSubmit(() => mutate());
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title>
      {{ t('shiftDestroyForm.title') }}
    </v-card-title>
    <v-form
      v-if="!isPending && !isSuccess"
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-card flat>
        <v-card-text>
          {{ t('shiftDestroyForm.confirmation') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            :text="props.closeButtonText"
            @click="$emit('close')"
          />
          <v-spacer />
          <v-btn
            color="red"
            type="submit"
            :disabled="isPending || isSuccess"
          >
            {{ t('shiftDestroyForm.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-col
      v-if="isPending"
      class="pb-20"
    >
      <v-row class="justify-center d-flex">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="primary"
        />
      </v-row>
      <v-row class="justify-center mt-8 d-flex">
        <p>Estamos eliminando el turno, esto puede tomar unos segundos...</p>
      </v-row>
    </v-col>
    <v-col v-if="isSuccess">
      <v-row class="justify-center d-flex">
        <v-icon
          icon="mdi-check-circle"
          color="primary"
          size="50"
        />
      </v-row>
      <v-row class="justify-center mt-8 d-flex">
        <p>Turno eliminado exitosamente!</p>
      </v-row>
    </v-col>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
