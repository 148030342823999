<script setup lang="ts">
import CalendarEvent from './calendar-event.vue';

interface Event {
  id: number,
  title: string,
  description: string,
}

interface Props {
  day: Date,
  events: Array<Event>,
}
const props = defineProps<Props>();
</script>
<template>
  <v-card flat>
    <v-card-title class="text-h6">
      <v-icon>
        mdi-calendar
      </v-icon>
      <span class="ml-2">
        {{ props.day.toLocaleDateString('es-CL') }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-col>
        <v-row
          v-if="events.length === 0"
        >
          No hay eventos para este día.
        </v-row>
        <v-row
          v-for="event in events"
          v-else
          :key="event.id"
          rows="3"
        >
          <calendar-event :event="event" />
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>
