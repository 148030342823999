<script setup lang="ts">
import { ref } from 'vue';
import { type ShiftsCalendarSummary, type ShiftsCalendarTotalSummary } from '@/api/shifts';
import { formatCurrency } from '@/utils/currency';

interface Props {
  summary: ShiftsCalendarSummary[];
  totalSummary: ShiftsCalendarTotalSummary;
}

const props = defineProps<Props>();

const headers = [
  { title: 'Precio por turno', value: 'price' },
  { title: 'Costo Bruto por turno', value: 'cost' },
  { title: 'Cantidad de Turnos', value: 'totalShifts', cellProps: { class: 'font-weight-bold text-center' } },
  { title: 'Ingreso Total', value: 'totalPrice' },
  { title: 'Costo Bruto Total', value: 'totalCost' },
  { title: 'Utilidad', value: 'totalProfit' },
  { title: 'Margen', value: 'margin' },
];

const expanded = ref([]);
</script>
<template>
  <v-expansion-panels class="mb-12">
    <v-expansion-panel>
      <v-expansion-panel-title class="font-weight-bold text-body-2">
        Resumen Mensual
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-data-table
          v-model:expanded="expanded"
          :headers="headers"
          :header-props="{ class: 'font-weight-bold' }"
          :items="[props.totalSummary]"
          :items-per-page="-1"
          class="elevation-1"
          show-expand
        >
          <template #item.totalPrice="{ item }">
            <v-chip color="success">
              {{ formatCurrency(item.totalPrice) }}
            </v-chip>
          </template>
          <template #item.totalCost="{ item }">
            <v-chip color="error">
              {{ formatCurrency(item.totalCost) }}
            </v-chip>
          </template>
          <template #item.totalProfit="{ item }">
            <v-chip color="primary">
              {{ formatCurrency(item.totalProfit) }}
            </v-chip>
          </template>
          <template #item.margin="{ item }">
            <v-chip color="primary">
              {{ item.margin }} %
            </v-chip>
          </template>
          <template #expanded-row>
            <tr
              v-for="row in props.summary"
              :key="`${row.price}${row.cost}`"
            >
              <td>
                <v-chip color="grey-darken-3">
                  {{ formatCurrency(row.price) }}
                </v-chip>
              </td>
              <td>
                <v-chip color="grey-darken-3">
                  {{ formatCurrency(row.cost) }}
                </v-chip>
              </td>
              <td class="text-center">
                {{ row.totalShifts }}
              </td>
              <td>
                <v-chip color="grey-darken-3">
                  {{ formatCurrency(row.totalPrice) }}
                </v-chip>
              </td>
              <td>
                <v-chip color="grey-darken-3">
                  {{ formatCurrency(row.totalCost) }}
                </v-chip>
              </td>
              <td>
                <v-chip color="grey-darken-3">
                  {{ formatCurrency(row.totalProfit) }}
                </v-chip>
              </td>
              <td>
                <v-chip color="grey-darken-3">
                  {{ row.margin }} %
                </v-chip>
              </td>
            </tr>
          </template>
          <template #bottom />
        </v-data-table>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
