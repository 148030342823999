<script setup lang="ts">
import { computed, inject, ref, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { patientsApi } from '@/api/patients';
import { type SerializedUser } from '@/api/users';
import PatientDiscardForm from './patient-discard-form.vue';
import PatientUndiscardForm from './patient-undiscard-form.vue';

const headers = [
  { title: 'Nombre', value: 'firstName', sortable: true },
  { title: 'Apellido', value: 'lastName', sortable: true },
  { title: 'RUT', value: 'dni' },
  { title: 'Apoderado', value: 'representative.fullName', sortable: true },
  { title: 'Teléfono Apoderado', value: 'representative.phoneNumber' },
  // { title: 'Estado', value: 'status' },
  { title: 'Acciones', value: 'actions' },
];

const currentUser = inject<Ref<SerializedUser>>('currentUser');
if (currentUser?.value?.companyUsesIdCode) {
  headers.unshift({ title: 'Código', value: 'idCode', sortable: true });
}

const { data: activePatients, refetch: refetchActivePatients, isPending } = useQuery({
  queryKey: ['activePatients'],
  queryFn: () => patientsApi.index('active'),
});

const { data: discardedPatients, refetch: refetchDiscardedPatients } = useQuery({
  queryKey: ['discardedPatients'],
  queryFn: () => patientsApi.index('discarded'),
});

const scope = ref('active');
const patients = computed(
  () => (scope.value === 'active' ? activePatients.value : discardedPatients.value),
);

const actionSuccess = ref(false);

// function getStatusColor(status: string) {
//   if (status === 'active') return 'green';

//   return 'yellow-darken-3';
// }

const search = ref('');
</script>
<template>
  <v-card class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="{ class: 'font-weight-bold' }"
      :items="patients"
      :search="search"
      :loading="isPending"
      loading-text="Cargando los pacientes..."
    >
      <template #top>
        <v-toolbar
          color="white"
          flat
        >
          <v-toolbar-title class="font-weight-bold">
            Pacientes
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar
          color="white"
          class="ml-2"
          flat
        >
          <v-tabs
            v-model="scope"
            color="primary"
          >
            <v-tab
              value="active"
              class="rounded-pill text-body-1"
            >
              Activos
            </v-tab>
            <v-tab
              value="discarded"
              class="rounded-pill text-body-1"
            >
              Archivados
            </v-tab>
          </v-tabs>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4 mb-4 v-col-4"
            prepend-inner-icon="mdi-magnify"
            single-line
            variant="underlined"
            hide-details
          />
          <v-btn
            class="mb-2 text-white border bg-primary"
            href="patients/new"
          >
            Agregar
          </v-btn>
        </v-toolbar>
      </template>
      <!-- <template #item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)">
          {{ item.humanStatus }}
        </v-chip>
      </template> -->
      <template #item.actions="{ item }">
        <div
          v-if="scope === 'active'"
          class="d-flex"
        >
          <v-btn
            icon="mdi-eye"
            size="small"
            class="me-2"
            :href="`/patients/${item.id}`"
          />
          <v-btn
            icon="mdi-pencil"
            size="small"
            class="me-2"
            :href="`/patients/${item.id}/edit`"
          />
          <v-dialog
            max-width="800"
            scrollable
          >
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                icon="mdi-archive"
                size="small"
              />
            </template>
            <template #default="{ isActive }">
              <patient-discard-form
                :patient="item"
                @close="isActive.value = false"
                @success="actionSuccess = true"
                @refetch="refetchActivePatients(); refetchDiscardedPatients();"
              />
            </template>
          </v-dialog>
        </div>
        <v-dialog
          v-else-if="scope === 'discarded'"
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-package-up"
              size="small"
            />
          </template>
          <template #default="{ isActive }">
            <patient-undiscard-form
              :patient="item"
              @close="isActive.value = false"
              @success="actionSuccess = true"
              @refetch="refetchActivePatients(); refetchDiscardedPatients();"
            />
          </template>
        </v-dialog>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="actionSuccess"
      :timeout="2000"
      color="success"
      rounded="pill"
    >
      <v-icon
        icon="mdi-check-circle"
        class="mr-4"
      />
      Cambios guardados exitosamente!
    </v-snackbar>
  </v-card>
</template>
