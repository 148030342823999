import { api } from './index';

const BASE_PATH = '/api/internal/charge_movements';

export type ChargeMovement = {
  id: number;
  caregiverId: number;
  date: string;
  movementType: 'refund' | 'adjustment';
  amount: number;
  description: string;
}

export interface ChargeMovementForm {
  id?: number;
  caregiverId: number;
  date: string;
  movementType: 'refund' | 'adjustment';
  amount: number;
  description: string;
}

export const chargeMovementsApi = {
  create(chargeMovementForm: ChargeMovementForm): Promise<ChargeMovement> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { chargeMovement: chargeMovementForm },
    }).then(response => response.data.chargeMovement);
  },
  update(chargeMovementForm: Partial<ChargeMovementForm>): Promise<ChargeMovement> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${chargeMovementForm.id}`,
      data: { chargeMovement: chargeMovementForm },
    }).then(response => response.data.chargeMovement);
  },
  destroy(id: number): Promise<void> {
    return api({
      method: 'delete',
      url: `${BASE_PATH}/${id}`,
    }).then(() => {});
  },
};
