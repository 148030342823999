import { api } from './index';

const BASE_PATH = '/api/internal/communes';

export type Commune = {
  name: string;
  regionId: number;
}

export const communesApi = {
  index(filter: string): Promise<Array<Commune>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?filter=${filter}`,
    }).then(response => response.data.communes);
  },
};
