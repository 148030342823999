<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { paymentsApi, type Payment, type PDFPayment, type XlsxPayments } from '@/api/payments';
import { useQuery } from '@tanstack/vue-query';
import { useFilters } from '@/composables/useFilters';
import { usePaymentsStore } from '@/stores/payments';
import { formatCurrency } from '@/utils/currency';
import PaymentsListDetail from '@/components/payments/payments-list-detail.vue';

const props = defineProps<{
  formattedDates: {
    fromDate: string;
    toDate: string;
  };
}>();

const store = usePaymentsStore();
if (!store.fromDate) store.fromDate = props.formattedDates.fromDate;
if (!store.toDate) store.toDate = props.formattedDates.toDate;

const filters = ref<{
  fromDate: string;
  toDate: string;
}>({
  fromDate: store.fromDate,
  toDate: store.toDate,
});

const queryParams = useFilters(filters.value);

const { data: payments, refetch, isFetching } = useQuery({
  queryKey: ['payments'],
  queryFn: () => paymentsApi.index(queryParams.value),
  initialData: [],
});

watch(filters, () => {
  store.fromDate = filters.value.fromDate;
  store.toDate = filters.value.toDate;
}, { deep: true });

const headers = [
  { title: 'Profesional', value: 'caregiver.fullName' },
  { title: 'Turnos asignados', value: 'shiftsCount' },
  { title: 'Monto Bruto', value: 'totalCost' },
  { title: 'Monto Líquido', value: 'cost' },
  { title: 'Ajustes', value: 'totalPaymentMovements' },
  { title: 'Pago a Profesional', value: 'amountPendingToPay' },
  { title: 'Ver Detalle', value: 'actions' },
];

const totalShiftsCount = computed(
  () => payments.value.reduce((acc: number, payment: Payment) => acc + payment.shiftsCount, 0),
);
const totalCost = computed(
  () => payments.value.reduce((acc: number, payment: Payment) => acc + payment.cost, 0),
);
const totalAmount = computed(
  () => payments.value.reduce((acc: number, payment: Payment) => acc + payment.totalCost, 0),
);
const totalPaymentMovements = computed(
  () => payments.value.reduce((acc: number, payment: Payment) => acc + payment.totalPaymentMovements, 0),
);
const totalAmountPendingToPay = computed(
  () => payments.value.reduce((acc: number, payment: Payment) => acc + payment.amountPendingToPay, 0),
);

const snackbarAlert = ref(false);
function downloadCaregiverPDF(caregiverPayment: Payment) {
  snackbarAlert.value = true;
  paymentsApi.show(caregiverPayment.caregiver.id, queryParams.value, 'pdf').then((response: PDFPayment) => {
    const { headers: responseHeaders, data, data: { type } } = response;
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    const link = document.createElement('a');
    link.href = url;
    link.download = responseHeaders['content-disposition'].split('"')[1];
    link.click();
  });
}

const emailSnackbarAlert = ref(false);
function sendCaregiverPaymentsViaEmail(caregiverPayment: Payment) {
  emailSnackbarAlert.value = true;
  paymentsApi.show(caregiverPayment.caregiver.id, queryParams.value, 'json');
}

function localizedDate(date: string) {
  return date.split('-').reverse().join('/');
}

const search = ref('');

const xlsxDownloadSnackbarAlert = ref(false);
function downloadPaymentsXlsx() {
  xlsxDownloadSnackbarAlert.value = true;
  paymentsApi.indexAsXlsx(queryParams.value).then((response: XlsxPayments) => {
    const { headers: responseHeaders, data, data: { type } } = response;
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    const link = document.createElement('a');
    link.href = url;
    link.download = responseHeaders['content-disposition'].split('"')[1];
    link.click();
  });
}
</script>
<template>
  <v-card class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="{ class: 'font-weight-bold' }"
      :items="payments"
      :items-per-page="-1"
      :search="search"
      :loading="isFetching"
      loading-text="Cargando los pagos..."
    >
      <template #top>
        <v-toolbar
          color="white"
          flat
        >
          <v-toolbar-title class="font-weight-bold">
            Pagos
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="justify-start d-flex align-center">
            <v-text-field
              v-model="filters.fromDate"
              class="px-2 v-col-3"
              type="date"
              label="Fecha desde"
              variant="outlined"
              density="compact"
            />
            <v-text-field
              v-model="filters.toDate"
              class="px-2 v-col-3"
              type="date"
              label="Fecha hasta"
              variant="outlined"
              density="compact"
            />
            <v-btn
              class="mb-6 mr-1 bg-primary"
              prepend-icon="mdi-magnify"
              :disabled="isFetching"
              @click="refetch"
            >
              Filtrar
            </v-btn>
            <v-spacer />
            <v-btn
              class="mb-6 mr-4"
              color="primary"
              prepend-icon="mdi-microsoft-excel"
              variant="outlined"
              :disabled="isFetching || xlsxDownloadSnackbarAlert"
              @click="downloadPaymentsXlsx"
            >
              Descargar
            </v-btn>
            <v-snackbar
              v-model="xlsxDownloadSnackbarAlert"
              :timeout="2000"
              color="primary"
              rounded="pill"
            >
              Se está generando el archivo Excel, puede tomar unos segundos...
            </v-snackbar>
          </v-row>
          <v-row class="justify-end d-flexn">
            <v-text-field
              v-model="search"
              label="Buscar"
              class="mx-4 v-col-3"
              prepend-inner-icon="mdi-magnify"
              single-line
              variant="underlined"
              hide-details
            />
          </v-row>
        </v-card-text>
      </template>
      <template #item.totalCost="{ item }">
        {{ formatCurrency(item.totalCost) }}
      </template>
      <template #item.cost="{ item }">
        {{ formatCurrency(item.cost) }}
      </template>
      <template #item.totalPaymentMovements="{ item }">
        {{ formatCurrency(item.totalPaymentMovements) }}
      </template>
      <template #item.amountPendingToPay="{ item }">
        <v-chip color="primary">
          {{ formatCurrency(item.amountPendingToPay) }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-dialog
          max-width="1400"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-eye"
              size="small"
              class="me-2"
            />
          </template>

          <template #default="{ isActive }">
            <payments-list-detail
              :payment="item"
              @close="isActive.value = false"
            />
          </template>
        </v-dialog>
        <v-btn
          icon="mdi-printer"
          size="small"
          class="me-2"
          @click="downloadCaregiverPDF(item)"
        />
        <v-snackbar
          v-model="snackbarAlert"
          :timeout="2000"
          color="primary"
          rounded="pill"
        >
          Se está generando el PDF, puede tomar unos segundos...
        </v-snackbar>
        <v-dialog
          v-if="item.caregiver.email"
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-email-arrow-right"
              size="small"
              class="me-2"
            >
              <v-icon icon="mdi-email-arrow-right" />
              <v-tooltip
                v-if="item.lastEmailSentAt"
                activator="parent"
                location="top"
              >
                Enviado hace {{ item.lastEmailSentAt }}
              </v-tooltip>
            </v-btn>
          </template>

          <template #default="{ isActive }">
            <v-card>
              <v-card-title class="ml-2 align-baseline d-flex">
                Enviar correo de pago
                <v-spacer />
                <v-btn
                  icon="mdi-window-close"
                  variant="plain"
                  @click="isActive.value = false"
                />
              </v-card-title>
              <v-card-text class="mt-4">
                Estás a punto de enviar un correo con el pago correspondiente al
                profesional <b>{{ item.caregiver.fullName }}</b> desde el <b>{{ localizedDate(filters.fromDate) }}</b>
                hasta el <b>{{ localizedDate(filters.toDate) }}</b>.
              </v-card-text>
              <v-card-text>
                Será enviado al profesional, al correo <b>{{ item.caregiver.email }}</b>.
              </v-card-text>
              <v-card-text v-if="item.lastEmailSentAt">
                El último correo de pago enviado para este profesional fue <b>hace {{ item.lastEmailSentAt }}</b>.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text="Enviar"
                  color="primary"
                  @click="isActive.value = false; sendCaregiverPaymentsViaEmail(item)"
                />
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-snackbar
          v-model="emailSnackbarAlert"
          :timeout="2000"
          color="primary"
          rounded="pill"
        >
          Se está enviando el correo, puede tomar unos segundos...
        </v-snackbar>
      </template>
      <template
        v-if="!search"
        #body.append
      >
        <tr>
          <th class="font-weight-bold">
            Total
          </th>
          <th class="font-weight-bold">
            {{ totalShiftsCount }}
          </th>
          <th class="font-weight-bold">
            {{ formatCurrency(totalAmount) }}
          </th>
          <th class="font-weight-bold">
            {{ formatCurrency(totalCost) }}
          </th>
          <th class="font-weight-bold">
            {{ formatCurrency(totalPaymentMovements) }}
          </th>
          <th>
            <v-chip color="grey-darken-3">
              {{ formatCurrency(totalAmountPendingToPay) }}
            </v-chip>
          </th>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
