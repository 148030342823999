<script setup lang="ts">
import { ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { availableCaregiversApi } from '@/api/available_caregivers';
import { type Caregiver } from '@/api/caregivers';
import { type Shift } from '@/api/shifts';
import { formatCurrency } from '@/utils/currency';
import { getCareerColor } from '@/utils/career-colors';

const props = defineProps<{
  shift: Shift;
}>();

const headers = [
  { title: 'Nombre', value: 'firstName', sortable: true },
  { title: 'Apellido', value: 'lastName', sortable: true },
  { title: 'Comuna', value: 'communeName' },
  { title: 'Especialidad', value: 'humanCareer', sortable: true },
  { title: 'Acciones', value: 'actions' },
];

const { data: availableCaregivers, isPending } = useQuery({
  queryKey: ['availableCaregivers'],
  queryFn: () => availableCaregiversApi.index(props.shift.id),
});

const search = ref('');

function generateWhatsappMessage(caregiver: Caregiver) {
  const message = `Hola ${caregiver.firstName}, te escribo por un turno disponible:\n` +
    '------------------\n' +
    `*Fecha*: ${props.shift.humanDate}\n` +
    `*Hora*: ${props.shift.humanTime}\n` +
    `*Paciente*: ${props.shift.patientFullName}\n` +
    `*Dirección*: ${props.shift.patientAddress ? props.shift.patientAddress : 'No disponible'}\n` +
    `*Pago Líquido*: ${formatCurrency(props.shift.caregiverCost)}\n` +
    '------------------\n' +
    '¿Te interesa? Por favor confirma por este medio.';

  return encodeURIComponent(message);
}
</script>
<template>
  <v-card class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="{ class: 'font-weight-bold' }"
      :items="availableCaregivers"
      :search="search"
      :loading="isPending"
      loading-text="Cargando los profesionales disponibles..."
    >
      <template #top>
        <v-toolbar
          color="white"
          flat
        >
          <v-toolbar-title class="font-weight-bold">
            Profesionales disponibles
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar
          color="white"
          class="ml-2"
          flat
        >
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4 mb-4 v-col-3"
            prepend-inner-icon="mdi-magnify"
            single-line
            variant="underlined"
            hide-details
          />
        </v-toolbar>
      </template>
      <template #item.humanCareer="{ item }">
        <v-chip :color="getCareerColor(item.career)">
          {{ item.humanCareer }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-btn
          v-if="item.phoneNumber"
          icon="mdi-whatsapp"
          size="small"
          color="success"
          :href="`https://wa.me/${item.phoneNumber}?text=${generateWhatsappMessage(item)}`"
          target="_blank"
        />
      </template>
    </v-data-table>
  </v-card>
</template>
