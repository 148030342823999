import type { SerializedUser } from '@/api/users';

type SiteVariables = {
  currentUser: SerializedUser;
}

interface KuidoWindow extends Window {
  siteVariables: SiteVariables;
}

export function useSiteVariables() {
  return (window as KuidoWindow & typeof globalThis).siteVariables;
}
