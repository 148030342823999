<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { paymentMovementsApi } from '@/api/payment-movements';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const emit = defineEmits(['close']);

const props = defineProps({
  paymentMovementId: {
    type: Number,
    required: true,
  },
});

const { handleSubmit } = useForm();

const queryClient = useQueryClient();

const { mutate, isError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => paymentMovementsApi.destroy(props.paymentMovementId),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['payments'] });
      emit('close');
    },
  },
);

const submitButtonDisabled = ref(false);
const onSubmit = handleSubmit(() => {
  submitButtonDisabled.value = true;
  mutate();
});
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title>
      {{ t('paymentMovementDestroyForm.title') }}
    </v-card-title>
    <v-form
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-card flat>
        <v-card-text>
          {{ t('paymentMovementDestroyForm.confirmation') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text="Cerrar"
            @click="$emit('close')"
          />
          <v-spacer />
          <v-btn
            color="red"
            type="submit"
            :disabled="submitButtonDisabled"
          >
            {{ t('paymentMovementDestroyForm.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
