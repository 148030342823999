import { decamelizeKeys } from 'humps';

function createQueryParams(params: object) {
  const decamelizedParams = Object.entries(decamelizeKeys(params));
  const activeParams = decamelizedParams.filter(([, value]) => value !== undefined && value !== null);

  return new URLSearchParams(activeParams).toString();
}

export { createQueryParams };
