import { inject, computed, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { usersApi, type SerializedUser } from '@/api/users';

export function useUser() {
  const { t } = useI18n();
  const user = inject<Ref<SerializedUser>>('currentUser');
  const clearCurrentUser = inject<() => void>('clearCurrentUser');
  const setCurrentUser = inject<(response: SerializedUser) => void>('setCurrentUser');

  const loggedIn = computed(() => !!user?.value);

  async function logout() {
    try {
      await usersApi.logout();
      clearCurrentUser?.();
      window.location.href = '/';
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(t('user.signOutError'));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return {
    user,
    loggedIn,
    logout,
    setCurrentUser,
  };
}
