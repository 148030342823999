<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation } from '@tanstack/vue-query';
import * as yup from 'yup';
import { usersApi } from '@/api/users';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const MIN_PASSWORD_LENGTH = 8;

const validationSchema = yup.object({
  email: yup.string().email().required(t('register.emailRequired')),
  password: yup.string().min(MIN_PASSWORD_LENGTH).required(t('register.passwordRequired')),
  passwordConfirmation: yup.string().min(MIN_PASSWORD_LENGTH).required(t('register.passwordConfirmationRequired'))
    .oneOf([yup.ref('password'), null], t('register.passwordConfirmationMismatch')),
});

const { handleSubmit, values, errors, defineField } = useForm({ validationSchema });

const [email, emailAttrs] = defineField('email');
const [password, passwordAttrs] = defineField('password');
const [passwordConfirmation, passwordConfirmationAttrs] = defineField('passwordConfirmation');

const { mutate: createUser, isError, error } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => usersApi.create({ ...values }),
    onSuccess: () => { window.location.href = '/caregivers'; },
  },
);

const onSubmit = handleSubmit(() => createUser());

const errorText = computed(() => {
  if (isError.value) {
    return (error.value?.response?.data as { [key: string]: string }).error;
  }

  return '';
});

</script>

<template>
  <div>
    <h3 class="mb-2 text-2xl font-semibold">
      {{ t('register.title') }}
    </h3>
    <p class="mb-8 text-gray-400">
      {{ t('register.subtitle') }}
    </p>

    <v-form @submit.prevent="onSubmit">
      <v-text-field
        v-model="email"
        v-bind="emailAttrs"
        variant="outlined"
        :label="t('register.email')"
        :error-messages="errors.email"
      />
      <v-text-field
        v-model="password"
        v-bind="passwordAttrs"
        type="password"
        variant="outlined"
        :label="t('register.password')"
        :error-messages="errors.password"
      />
      <v-text-field
        v-model="passwordConfirmation"
        v-bind="passwordConfirmationAttrs"
        type="password"
        variant="outlined"
        :label="t('register.passwordConfirmation')"
        :error-messages="errors.passwordConfirmation"
      />
      <v-btn
        color="primary"
        type="submit"
      >
        {{ t('register.submit') }}
      </v-btn>
      <v-btn
        class="ml-4"
        variant="outlined"
        href="/users/sign_in"
      >
        {{ t('register.hasAccount') }}
      </v-btn>
    </v-form>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ errorText || t('userSession.defaultError') }}
    </v-alert>
  </div>
</template>
