<script setup lang="ts">
import { inject, type Ref } from 'vue';
import { type Patient } from '@/api/patients';
import { type SerializedUser } from '@/api/users';
import GoBackButton from '@/components/go-back-button.vue';

const currentUser = inject<Ref<SerializedUser>>('currentUser');

interface Props {
  patient: Patient,
}

const props = defineProps<Props>();
</script>
<template>
  <v-container class="d-flex flex-column">
    <go-back-button path="/patients" />
    <v-card>
      <v-row class="d-flex flex-column flex-md-row">
        <v-col class="v-col-2">
          <v-avatar
            image="@assets/images/no-profile-picture.png"
            size="100"
            class="ma-4"
          />
        </v-col>
        <v-col class="v-col-8">
          <v-card-title class="font-weight-bold">
            {{ props.patient.firstName }} {{ props.patient.lastName }}
          </v-card-title>
          <v-card-text
            v-if="currentUser?.companyUsesIdCode"
            class="py-2"
          >
            Código: {{ props.patient.idCode }}
          </v-card-text>
          <v-card-text class="py-2">
            Nombre Social: {{ props.patient.nickname }}
          </v-card-text>
          <v-card-text class="py-2">
            Edad: {{ props.patient.age ? `${props.patient.age} años` : '' }}
          </v-card-text>
          <v-card-text class="py-2">
            Fecha de Nacimiento: {{ props.patient.humanBirthdate }}
          </v-card-text>
          <v-card-text class="py-2">
            Peso: {{ props.patient.weight ? `${props.patient.weight} kg` : '' }}
          </v-card-text>
          <v-card-text class="py-2">
            Dirección: {{ props.patient.address }}
          </v-card-text>
          <v-card-text class="py-2">
            Rut: {{ props.patient.dni }}
          </v-card-text>
          <v-card-text class="py-2">
            Contacto de Emergencia:
            {{ props.patient.representative.firstName }} {{ props.patient.representative.lastName }}
            - {{ props.patient.representative.phoneNumber }}
          </v-card-text>
        </v-col>
        <v-col class="v-col-2">
          <v-btn
            icon="mdi-pencil"
            color="primary"
            size="small"
            variant="outlined"
            class="ma-4"
            :href="`/patients/${props.patient.id}/edit`"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card class="justify-start mt-2 d-flex align-center">
      <v-btn
        color="primary"
        class="ma-4"
        :href="`/shifts/new?patient_id=${props.patient.id}`"
      >
        Agregar Turnos
      </v-btn>
      <v-btn
        color="primary"
        class="ma-4"
        :href="`/shifts?patient_id=${props.patient.id}`"
      >
        Ver Agenda
      </v-btn>
    </v-card>
    <v-expansion-panels class="mt-4">
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Antecedentes Médicos
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.patient.medicalHistory }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Antecedentes Quirúrgicos
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.patient.surgicalHistory }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Ayudas Técnicas
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.patient.technicalAids }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Seguros y Convenios
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.patient.insurances }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Comentarios y Observaciones
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.patient.comments }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
