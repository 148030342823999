import { api } from './index';
import { type Caregiver } from './caregivers';
import { type Patient } from './patients';

const BASE_PATH = '/api/internal/shifts';

interface Attendance {
  id: number;
  humanTime: string;
}

interface ReportImage {
  id: number;
  imageUrlLarge: string;
  humanCreatedAt: string;
  formattedText: string;
}

export type Shift = {
  id: number;
  patientId: number;
  patientFullName: string;
  patientAddress: string;
  caregiverId: number;
  caregiverFullName: string;
  startDatetime: string;
  startTime: string;
  endDatetime: string;
  endTime: string;
  caregiverCost: number;
  price: number;
  caregiverCostPerHour: number;
  pricePerHour: number;
  humanDate: string;
  humanTime: string;
  isWeekend: boolean;
  withTaxBill: boolean;
  humanTaxBill: string;
  hours: number;
  status: { name: string, color: string };
  checkIn: Attendance | null;
  checkOut: Attendance | null;
  reportImages: Array<ReportImage>;
  reportImagesSummary: string;
  canCheckInByAdmin: boolean;
  canCheckOutByAdmin: boolean;
  canReplaceCaregiver: boolean;
}

interface caregiverShift {
  day: string;
  dayShift: boolean;
  nightShift: boolean;
  customShift: boolean;
}

export interface ShiftBatchCreateForm {
  id?: number;
  patientId: number;
  caregiversIds: Array<number>;
  caregiversShifts: Array<Array<caregiverShift>>;
  caregiverWeekShiftCost: number | undefined;
  caregiverWeekendShiftCost: number | undefined;
  weekShiftPrice: number | undefined;
  weekendShiftPrice: number | undefined;
  caregiverWeekShiftCostPerHour: number | undefined;
  caregiverWeekendShiftCostPerHour: number | undefined;
  weekShiftPricePerHour: number | undefined;
  weekendShiftPricePerHour: number | undefined;
  startDate: string;
  withTaxBill: boolean;
  startTime: { hours: number; minutes: number };
  endTime: { hours: number; minutes: number };
}

export interface ShiftRecurrentCreateForm {
  patientId: number;
  caregiverId: number;
  caregiverWeekShiftCost: number;
  caregiverWeekendShiftCost: number;
  weekShiftPrice: number;
  weekendShiftPrice: number;
  caregiverWeekShiftCostPerHour: number;
  caregiverWeekendShiftCostPerHour: number;
  weekShiftPricePerHour: number;
  weekendShiftPricePerHour: number;
  withTaxBill: boolean;
  shiftType: string;
  startDate: string;
  endDate: string;
  startTime: { hours: number; minutes: number };
  endTime: { hours: number; minutes: number };
  recurrenceType: string;
  recurrenceInterval: number;
  recurrenceDays?: Array<string>;
  recurrent: boolean;
}

export interface ShiftForm {
  id?: number;
  patientId: number;
  caregiverId: number;
  caregiverCost: number;
  price: number;
  caregiverCostPerHour: number;
  pricePerHour: number;
  withTaxBill: boolean;
  shiftType: string;
  startTime: { hours: number; minutes: number };
  endTime: { hours: number; minutes: number };
  date?: string;
}

interface ShiftCalendarHeader {
  date: string;
  title: string;
  children: [{ value: string; }];
}

interface InstanceShifts {
  caregiver: Caregiver;
  patient: Patient;
}
export interface ShiftsCalendarSummary {
  price: number;
  cost: number;
  totalShifts: number;
  totalPrice: number;
  totalCost: number;
  totalProfit: number;
  margin: number;
}
export interface ShiftsCalendarTotalSummary {
  totalShifts: number;
  totalPrice: number;
  totalCost: number;
  totalProfit: number;
  margin: number;
}
interface ShiftsCalendar {
  shifts: Array<InstanceShifts>;
  headers: Array<ShiftCalendarHeader>;
  title: string;
  groupByClass: string;
  summary: ShiftsCalendarSummary[];
  totalSummary: ShiftsCalendarTotalSummary;
}

export const shiftsApi = {
  index(queryParams: string): Promise<Array<Shift>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?${queryParams}`,
    }).then(response => response.data.shifts);
  },
  indexAsCalendar(queryParams: string): Promise<ShiftsCalendar> {
    return api({
      method: 'get',
      url: `/api/internal/shifts_calendar?${queryParams}`,
    }).then(response => response.data);
  },
  create(shiftsFormData: ShiftBatchCreateForm | ShiftRecurrentCreateForm): Promise<Array<Shift>> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { shifts: shiftsFormData },
    }).then(response => response.data.shifts);
  },
  update(shiftForm: ShiftForm): Promise<Shift> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${shiftForm.id}`,
      data: { shift: shiftForm },
    }).then(response => response.data.shift);
  },
  destroy(id: number | undefined, queryParams: string | undefined): Promise<void> {
    let url;
    if (id) {
      url = `${BASE_PATH}/${id}`;
    } else if (queryParams) {
      url = `${BASE_PATH}?${queryParams}`;
    }

    return api({
      method: 'delete',
      url,
    }).then(() => {});
  },
};
