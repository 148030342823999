import { type ShiftForm, type ShiftBatchCreateForm } from '@/api/shifts';

interface Time {
  hours: number;
  minutes: number;
}

const eightAm = 8;
const eightPm = 20;

function formatShiftDay(date: string): string {
  return date.split('T')[0];
}

function isDayShift(startTime: Time, endTime: Time): boolean {
  return startTime.hours === eightAm && startTime.minutes === 0 && endTime.hours === eightPm && endTime.minutes === 0;
}

function isNightShift(startTime: Time, endTime: Time): boolean {
  return startTime.hours === eightPm && startTime.minutes === 0 && endTime.hours === eightAm && endTime.minutes === 0;
}

function determineShiftType(startTime: Time, endTime: Time):
  { dayShift: boolean; nightShift: boolean; customShift: boolean } {
  const dayShift = isDayShift(startTime, endTime);
  const nightShift = isNightShift(startTime, endTime);

  return {
    dayShift,
    nightShift,
    customShift: !dayShift && !nightShift,
  };
}

function transformShiftToShiftBatchCreateForm(shift: ShiftForm): ShiftBatchCreateForm {
  return {
    patientId: shift.patientId,
    caregiversIds: [shift.caregiverId],
    caregiversShifts: [
      [
        {
          day: formatShiftDay(shift.date),
          ...determineShiftType(shift.startTime, shift.endTime),
        },
      ],
    ],
    caregiverWeekShiftCost: shift.caregiverCost,
    caregiverWeekendShiftCost: shift.caregiverCost,
    weekShiftPrice: shift.price,
    weekendShiftPrice: shift.price,
    caregiverWeekShiftCostPerHour: shift.caregiverCostPerHour,
    caregiverWeekendShiftCostPerHour: shift.caregiverCostPerHour,
    weekShiftPricePerHour: shift.pricePerHour,
    weekendShiftPricePerHour: shift.pricePerHour,
    withTaxBill: shift.withTaxBill,
    startTime: shift.startTime,
    endTime: shift.endTime,
    startDate: '',
  };
}

export { transformShiftToShiftBatchCreateForm };

