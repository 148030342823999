<script setup lang="ts">

const props = defineProps<{
  path?: string,
}>();

function goBack() {
  window.history.back();
}
</script>
<template>
  <v-btn
    v-if="props.path"
    class="mb-4"
    max-width="100"
    :href="props.path"
  >
    <v-icon left>
      mdi-chevron-left
    </v-icon>
    Volver
  </v-btn>
  <v-btn
    v-else
    class="mb-4"
    max-width="100"
    @click="goBack"
  >
    <v-icon left>
      mdi-chevron-left
    </v-icon>
    Volver
  </v-btn>
</template>
