<script setup lang="ts">
import { inject, type Ref } from 'vue';
import { formatCurrency } from '@/utils/currency';
import { type Shift } from '@/api/shifts';
import { type SerializedUser } from '@/api/users';

const props = defineProps<{ shift: Shift }>();

const currentUser = inject<Ref<SerializedUser>>('currentUser');
</script>
<template>
  <v-row class="mt-2 mb-2 ml-1">
    <v-col>
      <v-card-text class="text-subtitle-2">
        Pago a Profesional (líquido):&nbsp;<b>{{ formatCurrency(props.shift.caregiverCost) }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Precio:&nbsp;<b>{{ formatCurrency(props.shift.price) }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Con Boleta de Honorarios:&nbsp;<b>{{ props.shift.humanTaxBill }}</b>
      </v-card-text>
    </v-col>
    <v-col v-if="currentUser?.companyChargesAndPaysPerHours">
      <v-card-text class="text-subtitle-2">
        Pago a Profesional por hora (líquido):&nbsp;<b>{{
          formatCurrency(props.shift.caregiverCostPerHour) }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Precio por hora:&nbsp;<b>{{ formatCurrency(props.shift.pricePerHour) }}</b>
      </v-card-text>
    </v-col>
  </v-row>
</template>
