<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { type Caregiver } from '@/api/caregivers';
import { caregiverDocumentsApi } from '@/api/caregiver-documents';
import CaregiverDocumentForm from '@/components/caregivers/caregiver-document-form.vue';
import CaregiverDocumentDestroyForm from '@/components/caregivers/caregiver-document-destroy-form.vue';

interface Props {
  caregiver: Caregiver;
}

const props = defineProps<Props>();

const headers = [
  { title: 'Nombre', value: 'name' },
  { title: 'Acciones', value: 'actions', sortable: false },
];

const { data: caregiverDocuments } = useQuery({
  queryKey: ['caregiverDocuments', props.caregiver.id],
  queryFn: () => caregiverDocumentsApi.index(props.caregiver.id),
});
</script>
<template>
  <v-data-table
    :headers="headers"
    :header-props="{ class: 'font-weight-bold' }"
    :items="caregiverDocuments"
    :items-per-page="-1"
    class="elevation-1"
  >
    <template #top>
      <v-toolbar
        color="white"
        flat
      >
        <v-spacer />
        <v-dialog
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-plus"
              color="primary"
              size="small"
              elevation="2"
            />
          </template>
          <template #default="{ isActive }">
            <caregiver-document-form
              :caregiver-id="props.caregiver.id"
              mode="new"
              @close="isActive.value = false"
            />
          </template>
        </v-dialog>
      </v-toolbar>
    </template>
    <template #item.actions="{ item }">
      <v-btn
        ref="noopener noreferrer"
        target="_blank"
        :href="item.documentUrl"
        icon="mdi-eye"
        size="small"
        class="me-2"
      />
      <v-dialog
        max-width="800"
        scrollable
      >
        <template #activator="{ props: activatorProps }">
          <v-btn
            v-bind="activatorProps"
            icon="mdi-pencil"
            size="small"
            class="me-2"
          />
        </template>
        <template #default="{ isActive }">
          <caregiver-document-form
            :caregiver-document="item"
            :caregiver-id="props.caregiver.id"
            mode="edit"
            @close="isActive.value = false"
          />
        </template>
      </v-dialog>
      <v-dialog
        max-width="800"
        scrollable
      >
        <template #activator="{ props: activatorProps }">
          <v-btn
            v-bind="activatorProps"
            icon="mdi-delete"
            size="small"
            class="me-2"
          />
        </template>

        <template #default="{ isActive }">
          <caregiver-document-destroy-form
            :caregiver-document="item"
            @close="isActive.value = false"
          />
        </template>
      </v-dialog>
    </template>
    <template #bottom />
  </v-data-table>
</template>
