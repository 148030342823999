import { api } from './index';

const BASE_PATH = '/api/internal/countries';

export type Country = {
  id: number;
  name: string;
}

export const countriesApi = {
  index(): Promise<Array<Country>> {
    return api({
      method: 'get',
      url: BASE_PATH,
    }).then(response => response.data.countries);
  },
};
