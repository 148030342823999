<script setup lang="ts">
import { computed, inject, ref, watch, type PropType, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import * as yup from 'yup';
import { type Caregiver } from '@/api/caregivers';
import { type Patient } from '@/api/patients';
import { shiftsApi, type Shift, type ShiftForm } from '@/api/shifts';
import type { AxiosError } from 'axios';
import { transformShiftToShiftBatchCreateForm } from '@/utils/shifts-functions';
import { isNumber } from '@/utils/number-functions';
import { type SerializedUser } from '@/api/users';

const { t } = useI18n();

const emit = defineEmits(['close', 'refetch', 'success']);

const currentUser = inject<Ref<SerializedUser>>('currentUser');

const props = defineProps({
  shift: {
    type: Object as PropType<Shift> || undefined,
    default: undefined,
  },
  closeButtonText: {
    type: String,
    default: 'Cerrar',
  },
  mode: {
    type: String as PropType<'new' | 'edit'>,
    default: 'edit',
  },
  date: {
    type: String,
    required: true,
  },
});

function getShiftType(startTime: string, endTime: string): string {
  if (startTime === '08:00' && endTime === '20:00') {
    return 'day';
  } else if (startTime === '20:00' && endTime === '08:00') {
    return 'night';
  }

  return 'custom';
}

function getHashedTime(time: string) {
  const [hours, minutes] = time.split(':').map((value) => parseInt(value, 10));

  return { hours, minutes };
}

const queryClient = useQueryClient();
const caregivers = queryClient.getQueryData<Caregiver[]>(['caregivers']);
const patients = queryClient.getQueryData<Patient[]>(['patients']);

const commonValidationSchema = {
  caregiverId: yup.string().required(t('shiftForm.fieldRequired')),
  withTaxBill: yup.boolean().required(t('shiftForm.fieldRequired')),
  shiftType: yup.string().required(t('shiftForm.fieldRequired')),
  startTime: yup.object({
    hours: yup.number().required(t('shiftForm.fieldRequired')),
    minutes: yup.number().required(t('shiftForm.fieldRequired')),
  }),
  endTime: yup.object({
    hours: yup.number().required(t('shiftForm.fieldRequired')),
    minutes: yup.number().required(t('shiftForm.fieldRequired')),
  }),
};

const normalValidationSchema = yup.object({
  ...commonValidationSchema,
  caregiverCost: yup.number().required(t('shiftForm.fieldRequired'))
    .typeError(t('shiftForm.fieldRequired')),
  price: yup.number().required(t('shiftForm.fieldRequired'))
    .typeError(t('shiftForm.fieldRequired')),
});

const perHourValidationSchema = yup.object({
  ...commonValidationSchema,
  caregiverCostPerHour: yup.number().required(t('shiftForm.fieldRequired'))
    .typeError(t('shiftForm.fieldRequired')),
  pricePerHour: yup.number().required(t('shiftForm.fieldRequired'))
    .typeError(t('shiftForm.fieldRequired')),
});

const validationSchema = currentUser?.value.companyChargesAndPaysPerHours ?
  perHourValidationSchema : normalValidationSchema;

function isWeekend() {
  const SATURDAY = 6;
  const SUNDAY = 0;
  const date = new Date(props.date.split('T')[0].replace(/-/g, '/'));

  return date.getDay() === SATURDAY || date.getDay() === SUNDAY;
}

const currentPatient = computed(
  () => patients?.find((patient: Patient) => patient.id === props.shift?.patientId),
);

function setShiftPrice(): number | undefined {
  if (!props.shift || !currentPatient.value) {
    return undefined;
  } else if (props.shift.price) {
    return props.shift.price;
  } else if (isWeekend()) {
    return currentPatient.value.lastWeekendShift?.price;
  } else if (!isWeekend()) {
    return currentPatient.value.lastWeekShift?.price;
  }

  return undefined;
}

function setShiftPricePerHour(): number | undefined {
  if (!props.shift || !currentPatient.value) {
    return undefined;
  } else if (props.shift.pricePerHour) {
    return props.shift.pricePerHour;
  } else if (isWeekend()) {
    return currentPatient.value.lastWeekendShift?.pricePerHour;
  } else if (!isWeekend()) {
    return currentPatient.value.lastWeekShift?.pricePerHour;
  }

  return undefined;
}

function setShiftCost(): number | undefined {
  if (!props.shift || !currentPatient.value) {
    return undefined;
  } else if (props.shift.caregiverCost) {
    return props.shift.caregiverCost;
  } else if (isWeekend()) {
    return currentPatient.value.lastWeekendShift?.caregiverCost;
  } else if (!isWeekend()) {
    return currentPatient.value.lastWeekShift?.caregiverCost;
  }

  return undefined;
}

function setShiftCostPerHour(): number | undefined {
  if (!props.shift || !currentPatient.value) {
    return undefined;
  } else if (props.shift.caregiverCostPerHour) {
    return props.shift.caregiverCostPerHour;
  } else if (isWeekend()) {
    return currentPatient.value.lastWeekendShift?.caregiverCostPerHour;
  } else if (!isWeekend()) {
    return currentPatient.value.lastWeekShift?.caregiverCostPerHour;
  }

  return undefined;
}

function setCaregiverId(): number | undefined {
  const caregiverInstance = caregivers?.find((caregiver: Caregiver) => caregiver.id === props.shift?.caregiverId);

  return caregiverInstance?.id || undefined;
}

function setWithTaxBill(): boolean | undefined {
  if (!props.shift || !currentPatient.value) {
    return undefined;
  } else if (props.shift.withTaxBill) {
    return props.shift.withTaxBill;
  } else if (currentPatient.value.lastWeekendShift) {
    return currentPatient.value.lastWeekendShift?.withTaxBill;
  } else if (currentPatient.value.lastWeekShift) {
    return currentPatient.value.lastWeekShift?.withTaxBill;
  }

  return undefined;
}

const commonInitialValues = {
  patientId: props.shift?.patientId,
  caregiverId: setCaregiverId(),
  withTaxBill: setWithTaxBill(),
  shiftType: props.shift?.startTime ? getShiftType(props.shift.startTime, props.shift.endTime) : undefined,
  startTime: props.shift?.startTime ? getHashedTime(props.shift.startTime) : undefined,
  endTime: props.shift?.endTime ? getHashedTime(props.shift.endTime) : undefined,
  date: props.date.split('T')[0],
};

const normalInitialValues = {
  ...commonInitialValues,
  caregiverCost: setShiftCost(),
  price: setShiftPrice(),
};

const perHourInitialValues = {
  ...commonInitialValues,
  caregiverCostPerHour: setShiftCostPerHour(),
  pricePerHour: setShiftPricePerHour(),
};

const initialValues = currentUser?.value.companyChargesAndPaysPerHours ? perHourInitialValues : normalInitialValues;

const { handleSubmit, values, errors, defineField } = useForm<ShiftForm>({ validationSchema, initialValues });

const [patientId, patientIdAttrs] = defineField('patientId');
const [caregiverId, caregiverIdAttrs] = defineField('caregiverId');
const [caregiverCost, caregiverCostAttrs] = defineField('caregiverCost');
const [price, priceAttrs] = defineField('price');
const [caregiverCostPerHour, caregiverCostPerHourAttrs] = defineField('caregiverCostPerHour');
const [pricePerHour, pricePerHourAttrs] = defineField('pricePerHour');
const [withTaxBill, withTaxBillAttrs] = defineField('withTaxBill');
const [shiftType, shiftTypeAttrs] = defineField('shiftType');
const [startTime, startTimeAttrs] = defineField('startTime');
const [endTime, endTimeAttrs] = defineField('endTime');

const { mutate: createShift, isError: isCreateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => {
      const shift = transformShiftToShiftBatchCreateForm(values);

      return shiftsApi.create(shift);
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['patients'] });
      emit('refetch');
      emit('success');
      emit('close');
    },
  },
);

const { mutate: updateShift, isError: isUpdateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => shiftsApi.update({ ...values, id: props.shift?.id }),
    onSuccess: () => {
      emit('refetch');
      emit('success');
      emit('close');
    },
  },
);

const submitButtonDisabled = ref(false);
const onSubmit = handleSubmit(() => {
  submitButtonDisabled.value = true;
  if (props.mode === 'edit') {
    updateShift();
  } else {
    createShift();
  }
});

watch(shiftType, () => {
  if (shiftType.value === 'day') {
    startTime.value = { hours: 8, minutes: 0 };
    endTime.value = { hours: 20, minutes: 0 };
  } else if (shiftType.value === 'night') {
    startTime.value = { hours: 20, minutes: 0 };
    endTime.value = { hours: 8, minutes: 0 };
  } else if (shiftType.value === '24') {
    endTime.value = startTime.value;
  }
});
watch(startTime, () => {
  if (shiftType.value === '24') {
    endTime.value = startTime.value;
  }
});

const titleDate = computed(() => {
  const date = props.date.split('T')[0];

  return date.split('-').reverse().join('/');
});
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title class="mb-4">
      {{ props.mode === 'edit' ? t('shiftForm.editTitle') : t('shiftForm.newTitle') }} {{ titleDate }}
    </v-card-title>
    <v-form
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-card flat>
        <v-row class="mx-2 mt-4 d-flex">
          <v-autocomplete
            v-model="patientId"
            v-bind="patientIdAttrs"
            class="px-2 v-col-6"
            variant="outlined"
            item-title="fullName"
            item-value="id"
            :label="t('shift.patient')"
            :error-messages="errors.patientId"
            :items="patients"
          />
          <v-autocomplete
            v-model="caregiverId"
            v-bind="caregiverIdAttrs"
            class="px-2 v-col-6"
            variant="outlined"
            item-title="fullName"
            item-value="id"
            :label="t('shift.caregiver')"
            :error-messages="errors.caregiverId"
            :items="caregivers"
          />
        </v-row>
        <v-select
          v-model="shiftType"
          v-bind="shiftTypeAttrs"
          :label="t('shiftForm.shiftTypeTitle')"
          :error-messages="errors.shiftType"
          :items="[
            { title: t('shiftForm.shiftTypeDay'), value: 'day' },
            { title: t('shiftForm.shiftTypeNight'), value: 'night' },
            { title: t('shiftForm.shiftType24'), value: '24' },
            { title: t('shiftForm.shiftTypeCustom'), value: 'custom' }
          ]"
          variant="outlined"
          class="px-4 v-col-6"
        />
        <v-col
          v-if="shiftType === '24'"
          class="mb-4 mt-n4 d-flex flex-column"
        >
          <p class="ml-6 mb-n3 text-caption text-grey">
            {{ t('shiftForm.shiftType24Info') }}
          </p>
          <vue-date-picker
            v-model="startTime"
            v-bind="startTimeAttrs"
            class="w-50 pa-4"
            time-picker
            auto-apply
            minutes-increment="15"
            minutes-grid-increment="15"
            placeholder="Hora Inicio"
            :auto-position="false"
          />
        </v-col>
        <div
          v-if="shiftType === 'custom'"
          class="mb-4 mt-n4 d-flex"
        >
          <v-col>
            <p class="ml-6 mb-n3 text-caption text-grey">
              {{ t('shiftForm.shiftTypeCustomStartInfo') }}
            </p>
            <vue-date-picker
              v-model="startTime"
              v-bind="startTimeAttrs"
              class="w-75 pa-4"
              time-picker
              auto-apply
              minutes-increment="15"
              minutes-grid-increment="15"
              placeholder="Hora Inicio"
              :auto-position="false"
            />
          </v-col>
          <v-col>
            <p class="ml-6 mb-n3 text-caption text-grey">
              {{ t('shiftForm.shiftTypeCustomEndInfo') }}
            </p>
            <vue-date-picker
              v-model="endTime"
              v-bind="endTimeAttrs"
              class="w-75 pa-4"
              time-picker
              auto-apply
              minutes-increment="15"
              minutes-grid-increment="15"
              placeholder="Hora Término"
              :auto-position="false"
            />
          </v-col>
        </div>
        <v-row
          v-if="currentUser?.companyChargesAndPaysPerHours"
          class="mx-2 d-flex"
        >
          <v-text-field
            v-model="pricePerHour"
            v-bind="pricePerHourAttrs"
            type="number"
            prefix="$"
            variant="outlined"
            class="px-2 v-col-6"
            :label="t('shift.pricePerHour')"
            :error-messages="errors.pricePerHour"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event)"
          />
          <v-text-field
            v-model="caregiverCostPerHour"
            v-bind="caregiverCostPerHourAttrs"
            type="number"
            prefix="$"
            variant="outlined"
            class="px-2 v-col-6"
            :label="t('shift.caregiverCostPerHour')"
            :error-messages="errors.caregiverCostPerHour"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event)"
          />
        </v-row>
        <v-row
          v-else
          class="mx-2 d-flex"
        >
          <v-text-field
            v-model="price"
            v-bind="priceAttrs"
            type="number"
            prefix="$"
            variant="outlined"
            class="px-2 v-col-6"
            :label="t('shift.price')"
            :error-messages="errors.price"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event)"
          />
          <v-text-field
            v-model="caregiverCost"
            v-bind="caregiverCostAttrs"
            type="number"
            prefix="$"
            variant="outlined"
            class="px-2 v-col-6"
            :label="t('shift.caregiverCost')"
            :error-messages="errors.caregiverCost"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event)"
          />
        </v-row>
        <v-radio-group
          v-model="withTaxBill"
          v-bind="withTaxBillAttrs"
          :label="t('shiftBatchCreateForm.withTaxBillTitle')"
          :error-messages="errors.withTaxBill"
          inline
        >
          <v-radio
            :label="t('shiftBatchCreateForm.yes')"
            :value="true"
          />
          <v-radio
            :label="t('shiftBatchCreateForm.no')"
            :value="false"
          />
        </v-radio-group>
        <v-card-actions>
          <v-btn
            :text="props.closeButtonText"
            @click="$emit('close')"
          />
          <v-spacer />
          <v-btn
            v-if="mode === 'edit'"
            color="primary"
            type="submit"
            :disabled="submitButtonDisabled"
          >
            {{ t('shiftForm.editSubmit') }}
          </v-btn>
          <v-btn
            v-else-if="mode === 'new'"
            color="primary"
            type="submit"
            :disabled="submitButtonDisabled"
          >
            {{ t('shiftForm.newSubmit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      v-if="isCreateError || isUpdateError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
