import { type Shift } from '@/api/shifts';
import { api } from './index';

const BASE_PATH = '/api/internal/patients';

export type Patient = {
  id: number;
  idCode: number;
  firstName: string;
  lastName: string;
  nickname: string;
  fullName: string;
  dni: string;
  address: string;
  weight: number;
  birthdate: Date;
  humanBirthdate: string;
  age?: number;
  sex: string;
  humanSex: string;
  status: string;
  humanStatus: string;
  medicalHistory: string;
  surgicalHistory: string;
  technicalAids: string;
  insurances: string;
  comments: string;
  lastWeekShift: Shift;
  lastWeekendShift: Shift;
  representativesAttributes: [
    {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      dni: string;
    }
  ],
  representative: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    dni: string;
  }
}

export interface PatientForm {
  id?: number;
  idCode?: number;
  firstName: string;
  lastName: string;
  nickname: string;
  dni: string;
  address: string;
  locationExtended: boolean;
  latitude: string;
  longitude: string;
  weight: number;
  birthdate: Date;
  sex: string;
  medicalHistory: string;
  surgicalHistory: string;
  technicalAids: string;
  insurances: string;
  comments: string;
  representativesAttributes: [
    {
      id?: number;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      dni: string;
    }
  ]
}

export const patientsApi = {
  index(scope: string): Promise<Array<Patient>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?scope=${scope}`,
    }).then(response => response.data.patients);
  },
  create(patientForm: PatientForm): Promise<Patient> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { patient: patientForm },
    }).then(response => response.data.patient);
  },
  update(patientForm: Partial<PatientForm>): Promise<Patient> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${patientForm.id}`,
      data: { patient: patientForm },
    }).then(response => response.data.patient);
  },
  destroy(id: number): Promise<void> {
    return api({
      method: 'delete',
      url: `${BASE_PATH}/${id}`,
    }).then(() => {});
  },
};
