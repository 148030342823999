<script setup lang="ts">
import { inject, type Ref } from 'vue';
import { type Caregiver } from '@/api/caregivers';
import { shiftsApi, type Shift } from '@/api/shifts';
import { type SerializedUser } from '@/api/users';
import { useQuery } from '@tanstack/vue-query';
import { useFilters } from '@/composables/useFilters';
import GoBackButton from '@/components/go-back-button.vue';
import CaregiversDocuments from '@/components/caregivers/caregiver-documents.vue';

const currentUser = inject<Ref<SerializedUser>>('currentUser');

interface Props {
  caregiver: Caregiver,
}

const props = defineProps<Props>();

const headers = [
  { title: 'Fecha', value: 'humanDate' },
  { title: 'Horario', value: 'humanTime' },
  { title: 'Paciente', value: 'patientFullName' },
];

const monthlyFilter = useFilters({ startDateTimeFilter: 'monthly', caregiverIdEq: props.caregiver.id });
const { data: shifts } = useQuery({
  queryKey: ['shifts'],
  queryFn: () => shiftsApi.index(monthlyFilter.value),
});

const today = new Date();
function shiftsCountUntilToday() {
  return (shifts?.value ?? [])
    .filter((shift: Shift) => new Date(shift.startDatetime) <= today)
    .length;
}

</script>
<template>
  <v-container class="d-flex flex-column">
    <go-back-button path="/caregivers" />
    <v-card>
      <v-row class="d-flex flex-column flex-md-row">
        <v-col class="v-col-2">
          <v-avatar
            image="@assets/images/no-profile-picture.png"
            size="100"
            class="ma-4"
          />
        </v-col>
        <v-col class="v-col-8">
          <v-card-title class="font-weight-bold">
            {{ props.caregiver.firstName }} {{ props.caregiver.lastName }}
          </v-card-title>
          <v-card-text
            v-if="currentUser?.companyUsesIdCode"
            class="py-2"
          >
            Código: {{ props.caregiver.idCode }}
          </v-card-text>
          <v-card-text class="py-2">
            RUT: {{ props.caregiver.dni }}
          </v-card-text>
          <v-card-text class="py-2">
            Sexo: {{ props.caregiver.humanSex }}
          </v-card-text>
          <v-card-text class="py-2">
            Fecha de Nacimiento: {{ props.caregiver.humanDateOfBirth }}
          </v-card-text>
          <v-card-text class="py-2">
            Edad: {{ props.caregiver.age }}
          </v-card-text>
          <v-card-text class="py-2">
            Nacionalidad: {{ props.caregiver.countryName }}
          </v-card-text>
          <v-card-text class="py-2">
            Dirección: {{ props.caregiver.address }}
          </v-card-text>
          <v-card-text class="py-2">
            Correo: {{ props.caregiver.email }}
          </v-card-text>
          <v-card-text class="py-2">
            Teléfono: {{ props.caregiver.phoneNumber }}
          </v-card-text>
          <v-card-text class="py-2">
            Especialidad: {{ props.caregiver.humanCareer }}
          </v-card-text>
        </v-col>
        <v-col class="v-col-2">
          <v-btn
            icon="mdi-pencil"
            color="primary"
            size="small"
            variant="outlined"
            class="ma-4"
            :href="`/caregivers/${props.caregiver.id}/edit`"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-expansion-panels class="mt-4">
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Resumen Asistencia Mensual
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          <v-table>
            <thead>
              <tr>
                <th class="text-left">
                  Turnos Asignados
                </th>
                <th class="text-left">
                  Turnos Realizados
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ shifts?.length || 0 }}</td>
                <td>{{ shiftsCountUntilToday() }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Experiencia Laboral y Estudios
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.caregiver.description }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Próximos Turnos
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          <v-data-table
            :headers="headers"
            :header-props="{ class: 'font-weight-bold' }"
            :items="shifts"
          >
            <template #item.humanDate="{ item }">
              <p :class="item.isWeekend ? 'font-weight-black' : ''">
                {{ item.humanDate }}
              </p>
              <v-divider
                v-if="item.isWeekend"
                :thickness="3"
                color="primary"
                class="border-opacity-75"
              />
            </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Documentos
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          <caregivers-documents :caregiver="props.caregiver" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Comentarios y Observaciones
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2">
          {{ props.caregiver.comments }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
