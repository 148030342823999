<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<template>
  <v-btn
    color="primary"
    href="/users/sign_in"
  >
    {{ t('login.title') }}
  </v-btn>
</template>
