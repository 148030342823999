import type { AxiosHeaders } from 'axios';
import { api } from './index';
import { type Caregiver } from './caregivers';
import { type ChargeMovement } from './charge-movements';

const BASE_PATH = '/api/internal/charges';

export interface ShiftSummary {
  shiftsCount: number;
  price: number;
  totalPrice: number;
}
export interface ChargeSummary {
  caregiver: Caregiver;
  shiftType: string;
  shiftsCount: number;
  price: number;
  shiftsSummary: Array<ShiftSummary>;
}

export type Charge = {
  patient: {
    id: number;
    fullName: string;
    representative: {
      fullName: string;
      email: string;
    }
  };
  price: number;
  shiftsCount: number;
  chargesSummary: Array<ChargeSummary>;
  lastEmailSentAt: string;
  chargeMovements: Array<ChargeMovement>;
  totalChargeMovements: number;
  amountPendingToCharge: number;
}
export interface PDFCharge {
  headers: AxiosHeaders;
  data: Blob;
}

export interface XlsxCharges {
  headers: AxiosHeaders;
  data: Blob;
}

export const chargesApi = {
  index(queryParams: string): Promise<Array<Charge>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?${queryParams}`,
    }).then(response => response.data.charges);
  },
  indexAsXlsx(queryParams: string): Promise<XlsxCharges> {
    return api({
      method: 'get',
      url: `${BASE_PATH}.xlsx?${queryParams}`,
      responseType: 'blob',
    });
  },
  show(id: number, queryParams: string, format: string): Promise<PDFCharge> {
    return api({
      method: 'get',
      url: `${BASE_PATH}/${id}.${format}?${queryParams}`,
      responseType: format === 'pdf' ? 'blob' : 'json',
    });
  },
};
