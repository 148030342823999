<script setup lang="ts">
import { type Shift } from '@/api/shifts';

const props = defineProps<{ shift: Shift }>();
</script>
<template>
  <v-row class="mt-2 ml-1">
    <v-col>
      <v-card-text class="text-subtitle-2">
        Fecha:&nbsp;<b>{{ props.shift.humanDate }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Horario:&nbsp;<b>{{ props.shift.humanTime }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Cantidad de Horas:&nbsp;<b>{{ props.shift.hours }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Hora Inicio:&nbsp;<b>{{ props.shift.checkIn?.humanTime || '-' }}</b>
      </v-card-text>
    </v-col>
    <v-col>
      <v-card-text class="text-subtitle-2">
        Paciente:&nbsp;<b>{{ props.shift.patientFullName }}</b>
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Profesional:&nbsp;<b>{{ props.shift.caregiverFullName }}</b>
      </v-card-text>
      <v-card-text>
        &nbsp;
      </v-card-text>
      <v-card-text class="text-subtitle-2">
        Hora Término:&nbsp;<b>{{ props.shift.checkOut?.humanTime || '-' }}</b>
      </v-card-text>
    </v-col>
  </v-row>
</template>
