const careerColors = {
  'caregiver': 'green',
  'nurse_technician': 'blue',
  'nurse': 'red-darken-2',
  'auxiliary_nurse': 'orange-darken-2',
  'occupational_therapist': 'yellow-darken-3',
  'kinesiologist': 'cyan',
  'phonoaudiologist': 'pink',
  'doctor': 'brown-darken-2',
  'podologist': 'green-darken-4',
};

function getCareerColor(career: keyof typeof careerColors) {
  if (careerColors[career]) return careerColors[career];

  return 'purple';
}

export { getCareerColor };
