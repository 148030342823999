<script setup lang="ts">
const lastHealthStatus = {
  id: 1,
  date: new Date(),
  temperature: '36 °C',
  respiratoryRate: '20 rpm',
  heartRate: 80,
  bloodPressure: '120/80',
  oxygenSaturation: 98,
  painLevel: 3,
  hemoglucotest: 10,
  medications: [
    {
      id: 1,
      name: 'Paracetamol',
      dosage: '500 mg',
      dateTime: new Date().toLocaleString('es-CL'),
    },
    {
      id: 2,
      name: 'Ibuprofeno',
      dosage: '400 mg',
      dateTime: new Date().toLocaleString('es-CL'),
    },
  ],
  foodAndHidration: 'Almuerzo XYZ y 2 litros de Agua',
  toiletPee: '3 veces',
  toiletPoo: '1 vez',
  sleepQuality: 'Buena',
  prosthesis: 'No',
};
</script>
<template>
  <v-container>
    <v-col>
      <v-row>
        <v-btn href="/patients/1">
          <v-icon left>
            mdi-chevron-left
          </v-icon>
          Eugenio Pérez
        </v-btn>
      </v-row>
      <v-row class="justify-center mt-8">
        <v-card class="v-col-12 v-col-md-8">
          <v-card-title class="font-weight-bold">
            Último Estado de Salud
          </v-card-title>
          <v-card-text class="py-1">
            Fecha: {{ lastHealthStatus.date.toLocaleDateString('es-CL') }}
          </v-card-text>
          <v-card class="pb-2 ma-2">
            <v-card-title class="font-weight-bold">
              Control de Signos Vitales
            </v-card-title>
            <v-row class="d-flex flex-column flex-md-row">
              <v-col class="pb-0 pb-md-3">
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Temperatura:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.temperature }}
                </v-card-text>
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Frecuencia Respiratoria:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.respiratoryRate }}
                </v-card-text>
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Frecuencia Cardíaca:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.heartRate }}
                </v-card-text>
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Presión Arterial:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.bloodPressure }}
                </v-card-text>
              </v-col>
              <v-col class="pt-0 pt-md-3">
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Saturación de Oxígeno:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.oxygenSaturation }} %
                </v-card-text>
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Índice de Dolor:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.painLevel }}
                </v-card-text>
                <v-card-text class="py-1">
                  <p class="font-weight-light">
                    Hemoglucotest:
                  </p>
                  <v-icon
                    color="success"
                    icon="mdi-circle"
                  />
                  {{ lastHealthStatus.hemoglucotest }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="ma-2">
            <v-card-title class="font-weight-bold">
              Hábitos de la Vida Diaria
            </v-card-title>
            <v-card-text class="py-1">
              <p class="font-weight-light">
                Medicamentos:
              </p>
              <v-card-text
                v-for="medication in lastHealthStatus.medications"
                :key="medication.id"
                class="py-1"
              >
                {{ medication.name }} - {{ medication.dosage }}, {{ medication.dateTime }}
              </v-card-text>
            </v-card-text>
            <v-card-text class="py-1">
              <p class="font-weight-light">
                Comida e Hidratación:
              </p>
              {{ lastHealthStatus.foodAndHidration }}
            </v-card-text>
            <v-card-text class="py-1">
              <p class="font-weight-light">
                Diuresis:
              </p>
              {{ lastHealthStatus.toiletPee }}
            </v-card-text>
            <v-card-text class="py-1">
              <p class="font-weight-light">
                Deposiciones:
              </p>
              {{ lastHealthStatus.toiletPoo }}
            </v-card-text>
            <v-card-text class="py-1">
              <p class="font-weight-light">
                Calidad del Sueño:
              </p>
              {{ lastHealthStatus.sleepQuality }}
            </v-card-text>
            <v-card-text class="py-1">
              <p class="font-weight-light">
                Prótesis:
              </p>
              {{ lastHealthStatus.prosthesis }}
            </v-card-text>
          </v-card>
        </v-card>
      </v-row>
    </v-col>
  </v-container>
</template>
