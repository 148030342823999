import { api } from './index';

const BASE_PATH = '/api/internal/attendances';

export type Attendance = {
  id: number;
  caregiverId: number;
  shiftId: number;
  markType: string;
  markedAt: Date;
  markedBy: string;
}

export interface AttendanceForm {
  caregiverId: number;
  shiftId: number;
  markType: string;
}

export const attendancesApi = {
  create(attendanceForm: AttendanceForm): Promise<Attendance> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { attendance: attendanceForm },
    }).then(response => response.data.attendance);
  },
};
