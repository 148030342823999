<script setup lang="ts">
interface Event {
  id: number,
  title: string,
  description: string,
}

interface Props {
  event: Event,
}
const props = defineProps<Props>();
</script>
<template>
  <v-card class="pl-4 mt-2 w-100 d-flex justify-space-between align-center">
    <v-avatar
      image="@assets/images/no-profile-picture.png"
      size="50"
    />
    <v-container class="p-0 flex-column">
      <v-card-title>
        {{ props.event.title }}
      </v-card-title>
      <v-card-text>
        {{ props.event.description }}
      </v-card-text>
    </v-container>
  </v-card>
</template>
