<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { attendancesApi, type AttendanceForm } from '@/api/attendances';
import { caregiversApi } from '@/api/caregivers';
import { patientsApi } from '@/api/patients';
import { shiftsApi } from '@/api/shifts';
import { useFilters } from '@/composables/useFilters';
import ShiftShow from './actions/shift-show.vue';

const headers = [
  { title: 'Paciente', value: 'patientFullName' },
  { title: 'Profesional', value: 'caregiverFullName' },
  { title: 'Horario', value: 'humanTime' },
  { title: 'Hora Inicio', value: 'checkIn.humanTime' },
  { title: 'Hora Término', value: 'checkOut.humanTime' },
  { title: 'Estado', value: 'status' },
  { title: 'Acciones', value: 'actions' },
];

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const currentDate = ref(new Date());
const currentDateText = computed(
  () => {
    const text = currentDate.value.toLocaleDateString('es-CL', { dateStyle: 'full' }).split(' ');

    // eslint-disable-next-line no-magic-numbers
    return `${capitalize(text[0])} ${text[1]} ${text[2]} ${capitalize(text[3])} ${text[4]} ${text[5]}`;
  },
);

const filters = ref<{
  startDateTimeFilter: undefined | string
  offset: number
}>({
  startDateTimeFilter: 'today',
  offset: 0,
});
const queryParams = useFilters(filters.value);

function updateOffset(offset: number) {
  filters.value.offset += offset;
  currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() + offset));
}

function resetOffset() {
  filters.value.offset = 0;
  currentDate.value = new Date();
}

const { data: shifts, refetch, isFetching } = useQuery({
  queryKey: ['todayShifts'],
  queryFn: () => shiftsApi.index(queryParams.value),
});

watch(filters, () => {
  refetch();
}, { deep: true });

useQuery({
  queryKey: ['patients'],
  queryFn: () => patientsApi.index(),
});

useQuery({
  queryKey: ['caregivers'],
  queryFn: () => caregiversApi.index(),
});

const { mutate: createAttendance } = useMutation({
  mutationFn: (attendanceForm: AttendanceForm) => attendancesApi.create(attendanceForm),
  onSuccess: () => {
    refetch();
  },
});
</script>
<template>
  <v-card class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="{ class: 'font-weight-bold' }"
      :items="shifts"
      no-data-text="No hay turnos asignados para este día"
    >
      <template #top>
        <v-toolbar
          color="white"
          flat
        >
          <v-toolbar-title class="font-weight-bold">
            Turnos
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar
          color="white"
          flat
        >
          <v-btn
            variant="outlined"
            size="small"
            :disabled="isFetching"
            @click="resetOffset()"
          >
            Hoy
          </v-btn>
          <v-btn
            icon="mdi-chevron-left"
            :disabled="isFetching"
            @click="updateOffset(-1)"
          />
          <v-btn
            icon="mdi-chevron-right"
            :disabled="isFetching"
            @click="updateOffset(1)"
          />
          <p class="w-100">
            {{ currentDateText }}
          </p>
        </v-toolbar>
      </template>
      <template #item.status="{ item }">
        <v-chip :color="item.status.color">
          {{ item.status.name }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-dialog
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-eye"
              size="small"
            />
          </template>

          <template #default="{ isActive }">
            <shift-show
              :shift="item"
              @close="isActive.value = false"
              @refetch="refetch"
            />
          </template>
        </v-dialog>
        <v-tooltip
          v-if="item.canCheckInByAdmin"
          text="Marcar Inicio"
          location="top"
        >
          <template #activator="{ props }">
            <v-btn
              v-if="item.canCheckInByAdmin"
              v-bind="props"
              icon="mdi-location-enter"
              size="small"
              class="ml-2"
              @click="createAttendance({ shiftId: item.id, caregiverId: item.caregiverId, markType: 'check_in' })"
            />
          </template>
        </v-tooltip>
        <v-tooltip
          v-if="item.canCheckOutByAdmin"
          text="Marcar Término"
          location="top"
        >
          <template #activator="{ props }">
            <v-btn
              v-if="item.canCheckOutByAdmin"
              v-bind="props"
              icon="mdi-location-exit"
              size="small"
              class="ml-2"
              @click="createAttendance({ shiftId: item.id, caregiverId: item.caregiverId, markType: 'check_out' })"
            />
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
