<script setup lang="ts">
import { formatCurrency } from '@/utils/currency';
import { type Payment, type PaymentSummary, type ShiftSummary } from '@/api/payments';
import PaymentMovementForm from '@/components/payments/payment-movement-form.vue';
import PaymentMovementDestroyForm from '@/components/payments/payment-movement-destroy-form.vue';

interface Props {
  payment: Payment;
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);

interface CaregiverDetailRow {
  patient: string;
  shiftType: string;
  shiftsCount: number;
  cost: number;
  taxes: number;
  totalNetCost: number;
  totalNetTaxes: number;
  totalGrossAmount: number;
}

function generateCaregiverDetailRows(caregiverPayments: Payment) {
  const rows: Array<CaregiverDetailRow> = [];
  caregiverPayments.paymentsSummary.forEach((payment: PaymentSummary) => {
    payment.shiftsSummary.forEach((shifts: ShiftSummary) => {
      rows.push({
        patient: payment.patient.fullName,
        shiftType: payment.shiftType,
        ...shifts,
      });
    });
  }, []);

  return rows;
}

const headers = [
  { title: 'Paciente', value: 'patient' },
  { title: 'Tipo de Turno', value: 'shiftType' },
  { title: 'Cantidad de Turnos', value: 'shiftsCount' },
  { title: 'Cantidad de Turnos con Honorarios', value: 'shiftsCountWithTaxes' },
  { title: 'Pago por Turno', value: 'cost' },
  { title: 'Monto Bruto', value: 'totalGrossAmount' },
  { title: 'Retención Impuestos', value: 'totalNetTaxes' },
  { title: 'Pago a Profesional', value: 'totalNetCost' },
];

const paymentMovementHeaders = [
  { title: 'Fecha', value: 'date' },
  { title: 'Monto', value: 'amount' },
  { title: 'Descripción', value: 'description' },
  { title: 'Acciones', value: 'actions' },
];

function localizedDate(date: string) {
  return date.split('-').reverse().join('/');
}

function shiftTypeText(shiftType: string) {
  if (shiftType === 'weekday') return 'Semana';
  if (shiftType === 'weekend') return 'Fin de Semana';
  if (shiftType === 'holiday') return 'Feriado';
  if (shiftType === 'inalienable_holiday') return 'Feriado Irrenunciable';

  return '';
}
</script>
<template>
  <v-card class="pr-8">
    <v-card-title class="align-baseline d-flex">
      {{ `Detalles del Pago a ${props.payment.caregiver.fullName}` }}
      <v-spacer />
      <v-btn
        icon="mdi-window-close"
        variant="plain"
        @click="emit('close')"
      />
    </v-card-title>
    <v-expansion-panels class="ma-4">
      <v-expansion-panel>
        <v-expansion-panel-title class="font-weight-bold">
          Turnos
          <v-spacer />
          <v-chip
            color="green-darken-3"
            class="mr-8"
          >
            {{ formatCurrency(props.payment.cost) }}
          </v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-data-table
            :headers="headers"
            :header-props="{ class: 'font-weight-bold' }"
            :items="generateCaregiverDetailRows(props.payment)"
            :items-per-page="-1"
            class="elevation-1"
          >
            <template #item.shiftType="{ item }">
              {{ shiftTypeText(item.shiftType) }}
            </template>
            <template #item.cost="{ item }">
              {{ formatCurrency(item.cost) }}
            </template>
            <template #item.taxes="{ item }">
              {{ formatCurrency(item.taxes) }}
            </template>
            <template #item.totalGrossAmount="{ item }">
              {{ formatCurrency(item.totalGrossAmount) }}
            </template>
            <template #item.totalNetTaxes="{ item }">
              {{ formatCurrency(item.totalNetTaxes) }}
            </template>
            <template #item.totalNetCost="{ item }">
              <v-chip color="primary">
                {{ formatCurrency(item.totalNetCost) }}
              </v-chip>
            </template>
            <template #body.append>
              <tr>
                <th>-</th>
                <th>-</th>
                <th class="font-weight-bold">
                  {{ props.payment.shiftsCount }}
                </th>
                <th class="font-weight-bold">
                  {{ props.payment.shiftsCountWithTaxes }}
                </th>
                <th>-</th>
                <th class="font-weight-bold">
                  {{ formatCurrency(props.payment.totalCost) }}
                </th>
                <th class="font-weight-bold">
                  {{ formatCurrency(props.payment.taxes) }}
                </th>
                <th>
                  <v-chip color="green-darken-3">
                    {{ formatCurrency(props.payment.cost) }}
                  </v-chip>
                </th>
              </tr>
            </template>
            <template #bottom />
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Movimientos
          <v-spacer />
          <v-chip
            :color="props.payment.totalPaymentMovements < 0 ? 'error' : 'green-darken-3'"
            class="mr-8"
          >
            {{ formatCurrency(props.payment.totalPaymentMovements) }}
          </v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-data-table
            :headers="paymentMovementHeaders"
            :items="props.payment.paymentMovements"
            :header-props="{ class: 'font-weight-bold' }"
            :items-per-page="-1"
            class="elevation-1"
            no-data-text="No hay movimientos"
          >
            <template #top>
              <v-toolbar
                color="white"
                flat
              >
                <v-spacer />
                <v-dialog
                  max-width="800"
                  scrollable
                >
                  <template #activator="{ props: activatorProps }">
                    <v-btn
                      v-bind="activatorProps"
                      icon="mdi-plus"
                      color="primary"
                      size="small"
                      elevation="2"
                    />
                  </template>
                  <template #default="{ isActive }">
                    <payment-movement-form
                      :caregiver-id="props.payment.caregiver.id"
                      mode="new"
                      @close="isActive.value = false"
                    />
                  </template>
                </v-dialog>
              </v-toolbar>
            </template>
            <template #item.date="{ item }">
              {{ localizedDate(item.date) }}
            </template>
            <template #item.amount="{ item }">
              <v-chip :color="item.movementType === 'payment' ? 'error' : 'green-darken-3'">
                {{ formatCurrency(item.amount) }}
              </v-chip>
            </template>
            <template #item.actions="{ item }">
              <v-dialog
                max-width="800"
                scrollable
              >
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    icon="mdi-pencil"
                    size="small"
                    class="me-2"
                  />
                </template>
                <template #default="{ isActive }">
                  <payment-movement-form
                    :payment-movement="item"
                    :caregiver-id="props.payment.caregiver.id"
                    mode="edit"
                    @close="isActive.value = false"
                  />
                </template>
              </v-dialog>
              <v-dialog
                max-width="800"
                scrollable
              >
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    icon="mdi-delete"
                    size="small"
                    class="me-2"
                  />
                </template>

                <template #default="{ isActive }">
                  <payment-movement-destroy-form
                    :payment-movement-id="item.id"
                    @close="isActive.value = false"
                  />
                </template>
              </v-dialog>
            </template>
            <template #body.append>
              <tr>
                <th class="font-weight-bold">
                  Total
                </th>
                <th>
                  <v-chip :color="props.payment.totalPaymentMovements < 0 ? 'error' : 'green-darken-3'">
                    {{ formatCurrency(props.payment.totalPaymentMovements) }}
                  </v-chip>
                </th>
                <th>-</th>
                <th>-</th>
              </tr>
            </template>
            <template #bottom />
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        class="mt-4"
        readonly
      >
        <v-expansion-panel-title
          class="font-weight-bold"
          expand-icon=""
        >
          Total a Pagar
          <v-spacer />
          <v-chip color="primary mr-8">
            {{ formatCurrency(props.payment.amountPendingToPay) }}
          </v-chip>
        </v-expansion-panel-title>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
