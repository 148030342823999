<script setup lang="ts">
import { type Shift } from '@/api/shifts';

const props = defineProps<{ shift: Shift }>();

const headers = [
  { title: 'Fecha', value: 'humanCreatedAt' },
  { title: 'Imagen', value: 'imageUrlLarge' },
  { title: 'Texto', value: 'formattedText' },
];
</script>
<template>
  <v-data-table
    :headers="headers"
    :items="props.shift.reportImages"
    :items-per-page="-1"
    :height="props.shift.reportImages.length > 0 ? 400 : 200"
    item-key="id"
    class="pa-2"
    no-data-text="No hay registros subidos"
    hide-default-footer
  >
    <template #item.imageUrlLarge="{ item }">
      <v-dialog max-width="600">
        <template #activator="{ props: activatorProps }">
          <v-img
            v-bind="activatorProps"
            :src="item.imageUrlLarge"
            width="100"
            height="100"
            class="my-2"
            contain
          />
        </template>
        <template #default>
          <v-img :src="item.imageUrlLarge" />
        </template>
      </v-dialog>
    </template>
    <template #item.formattedText="{ item }">
      <v-dialog max-width="600">
        <template #activator="{ props: activatorProps }">
          <v-btn
            v-if="item.formattedText.length > 0"
            v-bind="activatorProps"
            icon="mdi-eye"
            size="small"
            class="me-2"
          />
        </template>
        <template #default>
          <v-card>
            <!-- eslint-disable-next-line vue/no-v-html vue/no-v-text-v-html-on-component -->
            <v-card-text v-html="item.formattedText" />
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template #bottom />
  </v-data-table>
</template>
