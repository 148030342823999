import { createApp, provide, ref } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { createI18n } from 'vue-i18n';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { useSiteVariables } from '../composables/useSiteVariables';
import type { SerializedUser } from '../api/users';
import { Locales } from '../locales/locales';
import App from '../components/app.vue';
import TheNavbar from '../components/the-navbar.vue';
import CalendarView from '../components/calendar/calendar-view.vue';
import CaregiverForm from '../components/caregivers/caregiver-form.vue';
import CaregiversList from '../components/caregivers/caregivers-list.vue';
import CaregiverProfile from '../components/caregivers/caregiver-profile.vue';
import ChargesList from '../components/charges/charges-list.vue';
import HealthStatusesView from '../components/health-statuses/health-statuses-view.vue';
import PatientForm from '../components/patients/patient-form.vue';
import PatientsList from '../components/patients/patients-list.vue';
import PatientProfile from '../components/patients/patient-profile.vue';
import PaymentsList from '../components/payments/payments-list.vue';
import ShiftBatchCreateForm from '../components/shifts/shift-batch-create-form.vue';
import ShiftsList from '../components/shifts/shifts-list.vue';
import ShiftsTodayList from '../components/shifts/shifts-today-list.vue';
import UserLoginForm from '../components/users/user-login-form.vue';
import UserSignUpForm from '../components/users/user-sign-up-form.vue';
import UserSession from '../components/users/user-session.vue';
import '../css/application.css';

export const i18n = createI18n({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'es',
  messages: Locales.messages,
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const vuetifyTheme = {
  dark: false,
  colors: {
    primary: '#5333ED',
  },
};

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'vuetifyTheme',
    themes: { vuetifyTheme },
  },
});

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      App,
      CalendarView,
      CaregiverForm,
      CaregiversList,
      CaregiverProfile,
      ChargesList,
      HealthStatusesView,
      PatientForm,
      PatientsList,
      PatientProfile,
      PaymentsList,
      ShiftBatchCreateForm,
      ShiftsList,
      ShiftsTodayList,
      TheNavbar,
      UserLoginForm,
      UserSignUpForm,
      UserSession,
    },
    setup() {
      const siteVariables = useSiteVariables();
      const currentUser = ref<SerializedUser | undefined>(siteVariables?.currentUser);
      provide('currentUser', currentUser);
      provide('clearCurrentUser', () => {
        currentUser.value = undefined;
      });
      provide('setCurrentUser', (user: SerializedUser) => {
        currentUser.value = user;
      });
    },
  });
  app.component('VueDatePicker', VueDatePicker);
  app.use(i18n);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(vuetify);
  app.mount('#vue-app');

  return app;
});
