<script setup lang="ts">
import { type Shift } from '@/api/shifts';

const props = defineProps<{ shift: Shift }>();

const noSummaryText = 'Actualmente no hay un resumen para este turno.';
</script>
<template>
  <v-col class="mt-2 ml-1">
    <v-card-text class="text-subtitle-2">
      {{ props.shift.reportImagesSummary || noSummaryText }}
    </v-card-text>
  </v-col>
</template>
